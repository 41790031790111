/// <reference path="_variables.scss" />
.grid-component-logos {
    padding:2rem 0;
    img {
        max-width: 200px;
        margin-bottom: 2rem;

        @include desktop() {
            margin-bottom: 0;
        }
    }
}
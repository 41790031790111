/// <reference path="_variables.scss" />
footer {
    color: #fff;

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    address {
        margin-top: 4rem;
        color: #CCD4D8;
    }

    .footer-primary {
        margin-top: -10rem;
        background-color: $color-headings;
        padding: 14rem 0 4rem;

        .footer-grid-component {
            img.grid-component-image {
                width: 280px;
                margin: auto;
                display: block;

                @include desktop() {
                    display: inline-block;
                }
            }
        }

        .grid-component-link-list {
            padding: 0;

            @include desktop() {
                padding: 0 2rem;
            }

            li {
                margin-bottom: 1.5rem;
            }
        }

        .linkedin-icon {
            vertical-align: text-top;
            margin-left: .5rem;
            width: 24px;
        }

        .contact-icon {
            margin-right: .5rem;
            width: 22px;
        }

        .desktop-external-logos {
            margin-top: 3rem;

            img {
                max-width: 100px;
                width: 100%;
            }
        }
    }

    .footer-secondary {
        background-color: $color-default-blue;
        border-bottom: 1px solid #253E58;
        padding: 1rem 0;
    }

    .text-gold {
        color: $color-yellow;
    }
}

.footer-message {
    $footer-cta-m-t: -4rem;
    margin-top: $footer-cta-m-t;

    .cta {
        margin: 2rem;
        background-color: #F7F8FB;
        padding: 5rem 4rem;
        border-radius: 15px;
        font-size: 18px;
        background-position: right top;
        background-repeat: no-repeat;

        @media only screen and (max-width: 1024px) {
            background-image: none !important;
        }



        @include desktop {
            margin: 2rem 0;
        }

        .footer--cta-highlight {
            color: $color-green;
        }

        .footer-message-text {
            @include desktop {
                width: 48%;
            }
        }
    }
    /* different bg colours set within CMS */
    &-navy {
        background-color: $color-headings;
    }
    &-light-blue {
        background-color: $color-light-blue;
    }
    &-off-white {
        background-color: $color-off-white;
    }
    &-very-dark-blue {
        background-color: $color-very-dark-blue;
    }

    @for $i from 0 through 20 {
        &-pull-up-#{$i} {
            margin-top: ($footer-cta-m-t - $i);
        }
    }
}

/// <reference path="../components/_variables.scss" />

.page-contenttype13meettheteam {
    .team-member-card {
        .card-body {
            padding: 30px 40px;

            .card-title {
                margin-bottom: 0.25rem;
            }

            .card-text {
                font-weight: 400;
                font-size: 18px;
                line-height: 29px;
                color: $color-grey;
                min-height: 87px;
            }

            img {
                vertical-align: text-bottom;
                margin-right: 0.25rem;
            }

            a {
                color: $color-headings;
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                &.linkedin-link {
                }
            }

            .team-no-link {
                display: block;
                min-height: 27px;
            }
        }
    }
}

/// <reference path="_variables.scss" />


.wme-form-field {
    border-radius: 61px;
    border: none;
    background-color: $color-off-white;
    padding: 10px 15px 10px 20px;
    color: $color-default-blue;
    font-weight: 500;
    font-size: 18px;
    transition: background-color 0.3s ease-in;
}

textarea.wme-form-field{
    border-radius:19px;
}

select.wme-form-field {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $color-off-white url("/static/icons/arrow-down-white.svg") no-repeat 95% 50%;

    @include desktop {
        width: 100%;
    }

    option {
        color: $color-headings;
        background-color: $color-off-white;
    }
}
.filter-bar {
    background-color: $color-mid-blue;
    padding: 1rem 0;

    .wme-form-field,
    .filter-pill {
        border: 1px solid $color-light-blue;
        background-color: $color-mid-blue;
        color: $color-white;
        padding: 10px 35px 10px 20px;
        border-radius:$default-border-radius;

        &:hover {
            background-color: $color-light-blue;
            border: 1px solid $color-headings;
        }
    }
}
a.filter-pill {
    text-decoration: none;
}

.field-validation-valid {
    display: none;
}

legend {
    font-weight: 400;
    margin:0 0 1rem;
}
label.upload-btn input{
    display:none;
}
.honey-pot,
.form-hp-area {
    input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }

    label {
        display: none;
    }
}
.form-field-error {
    margin-top: 0.25rem;
    border-radius: 36px;
    padding: 4px 14px;
}
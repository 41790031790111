/// <reference path="_variables.scss" />
.grid-component-case-study-list {
    padding:3rem 0 2rem;
    h3,.h3{
        font-weight:600;
        font-size:48px;
        line-height:60px;
        margin-bottom:3rem;
    }
    h4,.h4{
        color:$color-white;
    }
}
/// <reference path="_variables.scss" />
h1, .h1 {
    @include defaultFont(60px, $color-headings);
    line-height: 68px;
    font-weight: 500;
}

h2, .h2 {
    @include defaultFont(3rem, $color-headings, 500);
    line-height: 60px;
    margin-bottom: 2rem;
}

h3, .h3 {
    @include defaultFont(2rem, $color-headings, 500);
    line-height: 42px;
    margin-bottom: 2rem;
}

h4, .h4 {
    @include defaultFont(24px, $color-headings, 400);
    line-height: 34px;
    margin-bottom: 2rem;
}

h5, .h5 {
    @include defaultFont(1.125rem, $color-headings);
    line-height: 27px;
    margin-bottom: 2rem;
}

h6, .h6 {
    @include defaultFont(1.025rem, $color-headings);
    line-height: 26px;
    margin-bottom: 2rem;
}

.page-header,
footer {
    h1, .h1 {
        color: $color-white;
        margin-bottom: 2rem;
    }

    h3, .h3,
    h4, .h4 {
        color: $color-white;
    }
}

.highlight,
.highlight-yellow {
    color: $color-yellow;
}

.highlight-alt,
.highlight-green {
    color: $color-green;
}

.highlight-blue {
    color: $color-light-blue;
}
.highlight-mid-blue {
    color: $color-mid-blue;
}

.main-content .section-block-tertiary .grid-component-rte {
    color: #ffffff;
}

img.img-bordered {
    border-radius: $default-border-radius;
}

.category-pill {
    border: 1px solid $color-mid-blue;
    border-radius: $default-border-radius;
    padding: 0.5rem 1rem;
    color: $color-default-blue;
    font-weight: 500;

    &-on-dark {
        color: $color-white;
    }
}

a.category-pill {
    text-decoration: none;

    &:hover {
        background-color: $color-off-white;
    }
}

.pagination {
    li {
        &.active {
            a {
                color: $color-yellow;
                background-color: transparent;
            }
        }

        &.disabled {
            a {
                cursor: not-allowed;
                pointer-events: none;
            }
        }

        a {
            background-color: transparent;
            border: none;
            font-weight: 400;
            font-size: 24px;
            line-height: 43px;
            color: $color-white;
            padding: 6px 10px;

            &:hover {
                color: $color-yellow;
                background-color: transparent;
            }
        }
    }

    .prev {
        margin-right: 1rem;
    }

    .next {
        margin-left: 1rem;
    }

    img {
        vertical-align: middle;
        width: 24px;
        padding-top: 6px;
    }

    &-light {
        li {
            a {
                color: $color-grey;

                &:hover {
                    color: $color-green;
                }
            }

            &.active {
                a {
                    color: $color-default-blue;
                }
            }
        }
    }
}
.alert {
    h2 {
        margin-bottom: -10px;
        color: inherit;
    }
}
span.image-right img {
    float: right;
    margin: 0 0 0 1rem;
}
/// <reference path="_variables.scss" />
.grid-component-quote-carousel {
    .carousel-inner {
        @media only screen and (max-width: 600px) {
            overflow: visible;
        }
    }

    .carousel-item {
        min-height: 400px;
        background-repeat: no-repeat;
        background-position-x: 0;
        background-image: url('/static/icons/quote.svg');

        @media only screen and (min-width: 786px) {
            background-position-x: 15%;
        }

        @include desktop() {
            min-height: 300px;
        }


        .carousel-caption {
            color: #000;
            text-align: left;
            padding-top: 2rem;

            .blockquote .h4 {
                font-weight: 400;
                /*line-height: 2rem;*/
                margin-bottom: 2rem;
            }

            .blockquote-footer::before {
                content: '';
            }
        }

        figcaption {
            font-weight: 500;
            font-size: 1.125rem;
        }
    }
    .carousel-control-prev,
    .carousel-control-next {
        opacity: 1;
    }

    .carousel-control-prev-icon {
        background-image: url('/static/icons/arrow-left.svg');
    }

    .carousel-control-next-icon {
        background-image: url('/static/icons/arrow-right.svg');
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        filter: none;
    }

}
.section-block-quotecarouselgridcomponent.quotes-large {
    .carousel-item {
        min-height: 850px;

        @include tablet() {
            min-height: 440px;
        }
    }
}
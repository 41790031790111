/// <reference path="_variables.scss" />
@mixin pullItemUp() {
    margin-top: -16rem;
}

.main-content {
    padding-bottom: 4rem;


    .section-block:not(.policy-page):not(.section-block-numberedpagecardsgridcomponent):first-child {
        padding: 3rem 0 1rem;
    }

    .section-block {
        padding: 2rem 0;
        background-color: #fff;

        &-secondary {
            background-color: $color-off-white;
        }

        &-tertiary,
        &-dark-blue {
            background-color: $color-headings;
            color: $color-white;

            h2:not(.highlight), .h2:not(.highlight),
            h3:not(.highlight), .h3:not(.highlight),
            h4:not(.highlight), .h4:not(.highlight) {
                color: $color-white;
            }
        }

        &-dark-blue {
            background-color: $color-very-dark-blue;

            &.home-page-diagonal {
                clip-path: polygon(0 28%, 150% 0, 100% 100%, 0 100%);
                background-color: #001a2a;
                padding-top: 20rem !important;
                margin-top: -23rem;

                @include desktop() {
                    clip-path: polygon(0% 50%, 130% 0, 100% 100%, 0 100%);
                    background-color: #001a2a;
                    padding-top: 45rem !important;
                    margin-top: -46rem;
                }

                @include xl() {
                    clip-path: polygon(0% 56%, 121% 0, 100% 100%, 0 100%);
                    background-color: #001a2a;
                    padding-top: 45rem !important;
                    margin-top: -46rem;
                }
            }
        }

        &-off-white {
            background-color: $color-off-white;
        }
    }

    .section-block {

        &--double-padding {
            padding: 7rem 0 6rem;
        }
        /* items that require the inner component to be lifted up */
        &-statisticandctagridcomponent,
        &-popoutcardgridcomponent,
        &-casestudieslistgridcomponent {
            &.overlap-prev-section {
                .grid-component {
                    @include pullItemUp();
                }
            }
        }
        /* specific to the CTA banner*/
        &-calltoactionbannergridcomponent {
            &.overlap-prev-section {
                padding-bottom: 3rem;

                .grid-component {
                    margin-top: -12rem;
                }
            }
        }
        /* items that require the section to be lifted up */
        &-richtextgridcomponent {
            &.overlap-prev-section {
                margin-top: -20rem;
            }
        }

        &.overlap-on-next-section {
            padding-bottom: 10rem;

            .grid-component-quote-carousel {
                .carousel-inner {
                    margin-bottom: 6rem;
                }
            }
        }

        &.overlap-on-next-section {
            &.section-block-textandlogogridcomponent,
            &.section-block-numberedpagecardsgridcomponent,
            &.section-block-featuredcasestudygridcomponent {
                padding-bottom: 16rem;
            }
        }
        /*allow for homepage myWME & case studies*/
        &-casestudiesmywmegridcomponent {
            padding-top: 0;

            .grid-component-case-studies-my-wme {
                padding-top: 4rem;
            }
        }
        /* allow for variation of case studies block that has no padding due to being next to an item with a background colour */
        &-featuredcasestudiesandlistgridcomponent {
            padding-top: 0;

            .grid-component-case-studies-my-wme {
                margin-top: 0;
                padding: 2rem 0 2rem;

                @include desktop() {
                    padding: 8rem 0 2rem;
                }
            }
        }
    }
}

.overlap-header {
    .grid-component {
        margin-top: -14rem;
    }
}
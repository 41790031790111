/// <reference path="_variables.scss" />

.navbar-wme-main {

    @include desktop() {
        padding-top: 0;
        margin-bottom: 4rem;

        .nav-link,
        .nav-link.active,
        .nav-link.show {
            color: $color-white;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            padding-top: 1rem;
            margin-top: 0;

            &:after {
                border: none;
                display: none;
            }
        }

        .wme-main-menu-arrow-icon {
            margin-left: 1rem;
            transition: all 0.5s linear;
        }

        li {
            .pre-header-border-top {
                border-top: 3px solid transparent;
                display: block;
                min-height: 1px;
                width: 1%;
                margin: auto;
                transition: width linear .3s;
                margin-bottom: 0.5rem;
            }

            &.active,
            &:hover {
                .pre-header-border-top {
                    border-top-color: $color-yellow;
                    width: 80%;
                }
            }
        }
        /* make nav bar show on hover */
        .dropdown:hover > .dropdown-menu {
            display: block;
            margin-top: .7rem;
        }

        a.dropdown-toggle {

            &.show {
                .wme-main-menu-arrow-icon {
                    transform: rotate(-180deg);

                    path {
                        fill: $color-yellow;
                    }
                }
            }
        }

        .dropdown-menu {
            background: linear-gradient(172.98deg, #F7F8FB 2.27%, #007FA3 426.73%);

            &:before {
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
                content: " ";
                display: block;
                margin-top: -18px;
                left: 60px;
                position: absolute;
            }

            a {
                padding: 0 2.5rem 0.5rem;
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
                color: $color-headings;

                &:hover {
                    background-color: transparent;
                    /*text-decoration: underline;*/
                    color: $color-mid-blue;
                }

                .wme-menu-dropdown-icon {
                    margin-right: .5rem;
                }
            }
        }
    }
}

/* mobile menu */
@media only screen and (max-width: 1024px) {

    .navbar-nav {
        border-bottom: 1px solid #cccccc;
        padding-bottom: 1rem;
    }

    .dropdown-toggle {
        .wme-main-menu-arrow-icon {
            float: right;
            transform: rotate(180deg);
            transition: transform .25s ease-in;
        }

        &::after {
            display: none;
        }

        &.show {
            .wme-main-menu-arrow-icon {
                transform: rotate(0deg);
            }

            color: $color-mid-blue !important;
        }
    }

    a.nav-link {
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        color: $color-default-blue;
    }



    .dropdown-menu {
        border: none;

        &.show {
            a.dropdown-item {
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
            }
        }
    }

    .mobile-menu-sub-links {
        padding-top: 2rem;

        li {
            padding: 1rem 0;

            a {
                text-decoration: none;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: $color-headings;
            }

            &.ucul {
                a {
                    font-weight: 400;
                }

                img {
                    margin-right: 0.5rem;
                }
            }
        }
    }
}


/* hamburger menu button */

#nav-toggle-icon {
    width: 45px;
    height: 45px;
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    top: 35px;
    right: 25px;
    background-color: transparent;
    border: none;
    z-index: 9999;
}

#nav-toggle-icon span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    pointer-events: none;
}

#nav-toggle-icon span:nth-child(1) {
    top: 0px;
}

#nav-toggle-icon span:nth-child(2), #nav-toggle-icon span:nth-child(3) {
    top: 10px;
}

#nav-toggle-icon span:nth-child(4) {
    top: 20px;
}

#nav-toggle-icon.open span {
    background: #000;
}

#nav-toggle-icon.open span:nth-child(1) {
    top: 20px;
    width: 0%;
    left: 50%;
}

#nav-toggle-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-toggle-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-toggle-icon.open span:nth-child(4) {
    top: 20px;
    width: 0%;
    left: 50%;
}

.mobile-nav {
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    /*bottom: 0;*/
    right: 0;
    left: 0;
    z-index: 9998;
    background-color: #fff;
    z-index: 9998;
    display: none;

    &.open {
        display: block;
        opacity: 1;
        animation: menu-in .5s ease-in;
    }
}

@keyframes menu-in {
    0% {
        opacity: .1;
        display: block;
    }

    100% {
        opacity: 1;
    }
}

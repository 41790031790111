/* colours*/
$color-very-dark-blue: #001a2a;
$color-headings: #00263e;
$color-default-blue: #003057;
$color-mid-blue: #007fa3;
$color-light-blue: #77c5d5;
$color-orange: #e5554f;
$color-yellow: #f1b434;
$color-green: #00bf6f;
$color-punchy-blue: #1d4289;
$color-grey: #6c7e89;
$color-white: #ffffff;
$color-off-white: #edf0f6;

$default-font-family: "Outfit", sans-serif;

$default-border-radius:6px;

@mixin defaultFont($size: 18px, $color: $color-default-blue, $weight: normal) {
    font-family: $default-font-family;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
    font-size: $size;
    letter-spacing: 0;
    color: $color;
}

@mixin xl {
    @media only screen and (min-width: 1200px) {
        @content
    }
}
@mixin desktop {
    @media only screen and (min-width: 1024px) {
        @content
    }
}
@mixin medium {
    @media only screen and (min-width: 768px) {
        @content
    }
}
@mixin tablet {
    @media only screen and (min-width: 600px) {
        @content
    }
}

@mixin horizontal-list-li($float-direction: left) {
    float: $float-direction;
}

/// <reference path="_variables.scss" />
body {
    &.body-bg-secondary {
        background-color: $color-off-white;
    }

    &.body-bg-tertiary {
        background-color: $color-headings;
    }

    &.body-bg-dark-blue {
        background-color: $color-very-dark-blue;
    }

    &.body-bg-off-white {
        background-color: $color-off-white;
    }
}

/*added this as a blue bar is seen when stats are added to header image and the bg is set to dar blue*/
.body-bg-tertiary {
    header {
        background-color: #fff;
    }
}

header {

    .pre-header {
        background-color: $color-very-dark-blue;
        display: none;

        @include desktop() {
            display: block;
        }
    }

    .page-header {
        background-color: $color-default-blue;
        background-size: cover;
        background-image: url('/static/img/assets/page-header-bg.jpg');
        /* the image currently has a border top*/
        background-position-y: top;
        background-position-x: center;
        min-height: 500px;
        color: #fff;
        font-weight: 200;

        @include desktop() {
            background-position-y: -2px;
        }

        a {
            margin-top: 1rem;
        }

        ul.checkbox-list {
            margin: 1.5rem 0;
        }

        &--default {
            min-height: 200px;
        }

        .header-block {
            padding-top: 3rem;
            padding-bottom: 6rem;
            text-align: center;

            @include tablet() {
                padding-top: 7rem;
                text-align: left;
            }
        }

        &--date {
            color: $color-off-white;
            font-weight: 400;
        }

        &--default {
            .header-block {
                /*padding-top: 1rem;*/
                padding-bottom: 3rem;

                @include desktop() {
                    /*padding-top: 3rem;*/
                }
            }
        }

        &--stats {
            padding-bottom: 4rem;
        }

        .page-header-has-overlap {
            .header-block {
                padding-bottom: 14rem;
            }
        }

        .logo {
            /*width: 200px;*/
            padding-top: 2rem;
        }

        .header-block-customer-logo-section {
            margin-top: 5rem;
            padding-bottom: 6rem;

            @include desktop() {
                padding-bottom: 16rem;
            }

            .logo-fade-animation {
                display: flex;
                align-items: center;
                justify-content: center;

                @include desktop() {
                    justify-content: flex-start;
                }

                img {
                    max-width: 30%;
                    margin-right: 1rem;
                    transition: opacity 1s ease-in, display 1s ease-in;
                    opacity: 0;

                    @include desktop() {
                        max-width: 33%;
                        margin-right: 3rem;
                    }
                }
            }
        }

        .page-header-text {
            z-index: 100;
        }

        .page-header-image {
            margin-top: 1rem;
            z-index: 100;

            @include desktop() {
                margin-top: -6rem;
            }

            .main-image {
                mask-image: url(/static/img/header-image-mask-large.svg);
                mask-repeat: no-repeat;
                object-fit: cover;
                /*mask-position: center;
                ;*/
            }

            .secondary-image {
                mask-image: url(/static/img/header-image-mask-small.svg);
                mask-repeat: no-repeat;
                margin-top: -13rem;
                margin-left: 17rem;
                /*mask-position: center;
                object-fit: cover;*/
                @media only screen and (max-width: 600px) {
                    display: none;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    display: none;
                }
            }
        }

        .breadcrumb {
            display: none;

            @include tablet() {
                display: flex;
                margin-bottom: 2rem;
                margin-top: -3rem;

                li.breadcrumb-item {
                    font-weight: 400;
                    padding-left: 0.25rem;

                    &.breadcrumb-item + .breadcrumb-item::before {
                        color: $color-grey;
                        padding-left: 0.25rem;
                    }

                    a {
                        color: $color-grey;
                        text-decoration: none;
                        transition: color 0.25s ease-in;

                        &:hover {
                            color: $color-yellow;
                        }
                    }

                    &.active {
                        color: $color-yellow;
                    }
                }
            }
        }
    }
}

.card {
    border: none !important;
    margin-bottom: 1.5rem;
}

.alert-global {
    margin-bottom: 0;
    background-color: $color-light-blue;
    border-radius: 0;
    display:none;
    @include desktop(){
        display:block;
    }

    p,a {
        font-weight: 400;
        margin-bottom: 0;
        text-decoration:none;
        color:$color-default-blue;
    }
    a:hover{
        text-decoration:underline;
    }
}

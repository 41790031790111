/// <reference path="../components/_variables.scss" />

.page- {
    &contenttype15casestudies {
        .case-study-list {
            padding: 4rem 0;
        }

        .section-block-featuredcasestudygridcomponent {
            margin-top: 0 !important;
            padding-top: 6rem;
        }
    }
}

.card.card-case-study {
    .card-body {
        padding: 40px;
        background-color: $color-very-dark-blue;
        color: $color-white;

        .highlight {
            color: $color-light-blue;
        }

        a {
            margin-top: 2rem;
        }

        .category-pill {
            font-weight: normal;
            font-size: 14px;
            line-height: 27px;
        }

        .checkbox-list {
            margin-top: 5px;

            li {
                color: $color-green;
                font-weight: 600;
                font-size: 16px;
                line-height: 20.16px;
            }
        }
    }
}

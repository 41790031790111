/// <reference path="_variables.scss" />


@mixin myWMEContainer($bg-size) {
    background-color: $color-default-blue;
    background-size: $bg-size;
    background-image: url('/static/img/assets/page-header-bg.jpg');
    /* the image currently has a border top*/
    background-position-y: -2px;
    color: #fff;
    font-weight: 200;
    margin-bottom: 4rem;

    h2 {
        color: #fff;
        width: 90%;
    }

    .container-xl {
        padding-top: 4rem;
        padding-bottom: 0;

        @include xl() {
            padding-top: 8rem;
            background-image: url('/media/srupw5vo/my-wme-corner.png');
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: bottom;
            min-height: 800px;
        }

        &--text {
            margin-bottom: 2rem;
        }
    }

    .btn-primary {
        margin-right: 2rem;
    }
}


.section-block-mywmegridcomponent {
    @include myWMEContainer(cover);
}

.section-block-casestudiesmywmegridcomponent {
    .grid-component-mywme {
        @include myWMEContainer(initial);
        margin-top: -32rem;
        padding-top: 28rem;
    }
}

.main-content .section-block.section-block-mywmegridcomponent {
    padding: 0;
}

.grid-component-case-studies-my-wme {
    margin-top: 0;
    background-color: $color-off-white;

    @include desktop() {
        margin-top: 2rem;
        background-image: url('/static/img/assets/mywme-case-studies.jpg');
        background-repeat: no-repeat;
        background-position: center top;
        background-color: $color-headings;
    }

    .grid-component-case-study-list {
        @media only screen and (max-width:768px) {
            padding-top: 0;
        }

        h4 {
            color: $color-white;
        }
    }

    .grid-component-mywme--text {
        color: $color-white;

        h2 {
            color: $color-white;
        }
    }

    h3 {
        color: $color-headings;
    }
}

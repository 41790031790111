/// <reference path="_variables.scss" />
.stats-cta-container {
    .stats-cta {
        background-color: $color-very-dark-blue;
        border-radius: $default-border-radius 0 0 $default-border-radius;
        color: $color-white;
        padding: 3rem;

        &--stat {
            text-align: center;

            @media only screen and (min-width: 1024px) {
                margin-right: 1rem;
                border-right: 1px solid #fff;
                text-align: left;
                padding-right: 1rem;
            }

            &--last {
                border-right: none;
            }

            &--value {
                font-size: 48px;
                line-height: 60px;
                font-weight: 500;
                display: inline;
                margin-bottom: 0;
                color: $color-yellow;
            }

            &--label {
                font-size: 18px;
                line-height: 29px;
                font-weight: 400;
                display: inline;
            }
        }
    }

    &.stats-cta--header {
        margin-top: -8rem;

        .contact-cta-in-page {
            background-color: $color-off-white;
        }

        @include desktop() {
            padding: 4rem 2rem 2rem;
        }

        .stats-cta {
            padding: 2rem;

            &--stat {

                &--value {
                    display: block;
                }

                &--label {
                    display: block;
                }
            }
        }
    }

    .contact-cta-in-page {
        margin-top: 1rem;

        @include desktop() {
            margin-top: -17rem;

            &.no-subtitle {
                margin-top: -15rem;
            }
        }
    }
}

.main-content .section-block.overlap-prev-section .grid-component.grid-component-stat-and-cta {
    margin-top: -6rem;
}

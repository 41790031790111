/// <reference path="_variables.scss" />
.main-content > .section-block.faq-list:first-child {
    padding: 4rem 0 6rem !important;
}
.faq-list {
    padding: 1rem 0 3rem;

    .faq {
        background-color: $color-white;
        padding: 3rem;
        margin-bottom: 1rem;
        border-radius: $default-border-radius;


        h2 {
            font-size: 24px;
            line-height: 34px;
            font-weight: 400;
            margin-bottom: 1rem;
            display: inline-block;
            width: 95%;
        }

        .faq-text {
            font-size: 18px;
            line-height: 29px;
            font-weight: 400;
            transition: height 5s ease-in, overflow 5s ease-in;
            height: auto;
            overflow: hidden;

            &.collapsed {
                height: 0;
            }
        }
    }
}
/// <reference path="_variables.scss" />
.grid-component-page-cards {
    h2, .h2 {
        margin-bottom: 3rem;
    }

    .card {
        border: none !important;
        margin-bottom: 1.5rem;

        h4, .h4 {
            color: $color-headings !important;
        }

        .card-body {
            border: 1px solid #dadada;
            border-radius: 0 0 $default-border-radius $default-border-radius;
            padding: 3rem 1rem;
            box-shadow: 3px 2px 20px 0px #cdcaca;

            @include tablet() {
                padding: 3rem;
            }

            a.btn {
                margin-top: 2rem;
            }
        }

        .sector-icon {
            margin-right: 5px;
            vertical-align: middle;
        }
    }
}

.services-number-card h2 {
    color: $color-light-blue;
}

.main-content {
    .section-block {
        &.overlap-on-next-section {
            .grid-component-page-cards {
                padding-bottom: 4rem;
            }

            &.overlap-next-section-double-gap {
                .grid-component-page-cards {
                    padding-bottom: 18rem;
                }
            }
        }

        &.overlap-prev-section {
            .grid-component-page-cards {
                margin-top: -14rem;
            }

            &.overlap-prev-section-double-gap {
                .grid-component-page-cards {
                    margin-top: -18rem;
                }
            }
        }
        /*cards on dar background don't have box shadow*/
        &.section-block-dark-blue {
            .card {
                .card-body {
                    box-shadow: none;
                }
            }
        }
    }
}

.numbered-page-card-container {
    margin-bottom:2rem;
    &--number {
        font-size: 60px;
        font-weight: 500;
        line-height: 68px;
        color: $color-light-blue;
        margin-right: 2rem;
        vertical-align: middle;
    }

    &--line {
        border-left: 2px solid #00305733;
        margin-left: 3rem;
        margin-right: -4rem;
    }
    &--no-line {
        border-left: 2px solid transparent;
    }

    @media only screen and (min-width: 768px) {
        .col-md-5 {
            width: 46%;
        }
    }
}

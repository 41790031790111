/// <reference path="_variables.scss" />
.grid-component-case-study-feature-banner {
    @media only screen and (min-width: 768px) {
        margin-bottom: -10rem;
    }

    img {
        border-radius: $default-border-radius;
    }

    &--caption {
        padding: 1rem;

        @media only screen and (min-width: 768px) {
            position: relative;
            bottom: 30%;
            right: 0;
            width: 80%;
            left: 20%;
            border-radius: $default-border-radius 0 0 $default-border-radius;
            background-color: $color-headings;
            color: $color-white;
        }

        @include desktop() {
            bottom: 30%;
            width: 60%;
            left: 40%;
        }

        @media only screen and (min-width: 1200px) {
            padding: 2.25rem 2rem;
            bottom: 27%;
            width: 38%;
            left: 62%;
        }
    }

    &--text-top {
        font-size: 24px;
        line-height: 34px;
        font-weight: 400;
        margin-bottom: 0;
    }

    &--text-bottom {
        font-size: 32px;
        line-height: 42px;
        font-weight: 500;
        margin-bottom: 0;

        .highlight {
            color: $color-light-blue;
        }
    }
}

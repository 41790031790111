/// <reference path="../components/_variables.scss" />

@mixin articleDate() {
    font-weight: 400;
    color: $color-grey;
}

@mixin articleGalleryCard() {
    .card-body {
        padding: 30px;

        .card-date {
            @include articleDate;
        }

        .h4 a {
            color: $color-default-blue;
            text-decoration: none;
        }
    }
}

.article-list {
    padding-top: 3rem;

    

    .card {
        .card-body {
            padding: 30px;

            .card-date {
                @include articleDate;
            }

            .h4 a {
                color: $color-default-blue;
                text-decoration: none;
            }
        }
    }
}
.featured-article-card {
    background-color: $color-white;
    border-radius: $default-border-radius;
    margin-bottom: 2rem;

    @include medium {
        display: flex;
    }

    &--image {

        img {
            width: 100%;
            border-radius: $default-border-radius $default-border-radius 0 0;
        }

        @include medium {
            width: 60%;

            img {
                border-radius: $default-border-radius 0 0 $default-border-radius;
            }
        }
    }

    &--text {
        padding: 2rem;

        @include medium {
            padding: 2rem 1rem 0;
            width: 40%;
        }

        @include xl {
            padding: 14rem 4rem 0;
        }

        &--date {
            @include articleDate;
        }

        .h3 a {
            color: $color-default-blue;
            text-decoration: none;
        }
    }
}
.page-contenttype5newsarticle {
    .article-content {

        @include tablet() {
            padding-bottom: 8rem;
        }

        img {
            max-width: 100%;
            &:not(.no-rounded-border){
                border-radius:$default-border-radius;
            }
        }
    }

    .article-related-articles {
        .card {
            @include articleGalleryCard;
        }
    }
}

/// <reference path="_variables.scss" />
.grid-component-image-and-text {
    @include desktop() {
        padding: 2rem 0;
    }

    &--text {
        @include desktop() {
            padding: 8rem 0;
        }

        .h3 {
            font-weight: 500;
        }

        .highlight {
            color: $color-green;
        }

        .btn-alternative {
            margin-top: 1rem;
        }
    }

    &--image {
        margin: 2rem 0;

        @include desktop() {
            margin: 0;
        }

        img {
            border-radius: $default-border-radius;
        }
    }

    &--left {
        .grid-component-image-and-text--text {
            @include desktop() {
                margin-left: 5rem;
            }
        }
    }

    &--right {
        .grid-component-image-and-text--text {
            @include desktop() {
                margin-right: 5rem;
            }
        }
    }
}

/// <reference path="_variables.scss" />
.grid-component-text-list {
    @include desktop() {
        padding: 2rem 0;
    }

    &--text {
        padding: 3rem;

        &--title {
            font-weight: 500;
            font-size: 32px;
            line-height: 42px;
        }
    }

    &--list {
        padding: 3rem;
        background-color: $color-headings;
        border-radius: $default-border-radius;
        color: $color-white;

        &--title {
            font-weight: 500;
            font-size: 32px;
            line-height: 42px;
        }
    }

    ul.checkbox-list-horizontal {

        li {
            line-height: 29px;
            font-weight: 400;
        }
    }
}

/// <reference path="../components/_variables.scss" />

.contact-form-block {
    border-radius: $default-border-radius;
    background-color: $color-white;
    padding: 2rem;

    &--tel {
        a {
            text-decoration: none;
            color: $color-mid-blue;
        }
    }
}
.contact-page-form{
    legend{
        font-weight:400;
        margin-bottom:2rem;
        font-size:18px;
    }
}
/// <reference path="_variables.scss" />
.grid-component-logo-and-text-centered {
    padding: 2rem 0;

    &--text {


        @include tablet() {
            padding: 3rem 2rem;
        }
    }

    &--image {
        text-align: center;

        img {
            margin-bottom: 2rem;
        }
    }
}

/// <reference path="../components/_variables.scss" />

.careers-list,
.vacancy-page {
    padding: 2rem 0 10rem !important;
}

.vacancy-listing {
    border-radius: $default-border-radius;
    background-color: $color-white;
    padding: 2rem 3rem;
    margin-bottom: 1.5rem;

    h4 {
        margin-bottom: 0.5rem;
    }

    .vacancy-meta {
        color: $color-mid-blue;
        font-weight: 400;

        .vacancy-meta-seperator {
            margin: 0 0.5rem 0 0;
            padding-right: 0.75rem;
            border-right: 1px solid $color-grey;
        }

        .vacancy-icon {
            margin-right: 0.25rem;
            vertical-align: text-bottom;
        }
    }

    @include desktop() {
        .btn-primary {
            margin-top: 1rem;
        }
    }
}

/// <reference path="_variables.scss" />
.grid-component-twin-image-card {
    margin-bottom: 3rem;
    padding: 4rem 2rem;

    @include desktop() {
        padding: 4rem 0;
    }

    .twin-image--card {
        @include desktop() {
            padding: 6rem 4rem 4rem 10rem;
        }

        .h3 {
            font-weight: 500;
        }
    }

    .btn-alternative {
        margin-top: 1rem;
    }

    .twin-image--main {
        mask-image: url(/static/img/header-image-mask-large.svg);
        mask-repeat: no-repeat;
        object-fit: cover;
    }

    .twin-image--secondary {
        mask-image: url(/static/img/header-image-mask-small.svg);
        mask-repeat: no-repeat;
        margin-top: -13rem;
        margin-left: 17rem;
    }
}

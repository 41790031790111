/*@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css');*/
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@import 'components/_variables.scss';

@import 'components/_buttons.scss';
@import 'components/_case-study-feature-banner.scss';
@import 'components/_case-study-list.scss';
@import 'components/_cta-banner.scss';
@import 'components/_faqs.scss';
@import 'components/_featured-case-study.scss';
@import 'components/_footer.scss';
@import 'components/_forms.scss';
@import 'components/_icons.scss';
@import 'components/_image-and-text.scss';
@import 'components/_layout.scss';
@import 'components/_link-list.scss';
@import 'components/_logo-and-text-centered.scss';
@import 'components/_my-wme.scss';
@import 'components/_nav-menu.scss';
@import 'components/_overlay-card.scss';
@import 'components/_page-cards.scss';
@import 'components/_popout-card.scss';
@import 'components/_quick-links.scss';
@import 'components/_quotes.scss';
@import 'components/_resources-download.scss';
@import 'components/_section-blocks.scss';
@import 'components/_stats-cta.scss';
@import 'components/_text-and-list.scss';
@import 'components/_text-and-logo.scss';
@import 'components/_twin-image-card.scss';
@import 'components/_typography.scss';

@import 'pages/_careers.scss';
@import 'pages/_case-study.scss';
@import 'pages/_contact.scss';
@import 'pages/_frameworks.scss';
@import 'pages/_meet-the-team.scss';
@import 'pages/_news.scss';



body {
    @include defaultFont();
    line-height: 29px;
}
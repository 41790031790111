/// <reference path="_variables.scss" />
.btn {
    &-primary {
        background-color: $color-light-blue;
        font-weight: 500;
        font-size: 18px;
        padding: 13px 30px;
        border-radius: 50px;
        border: none;
        color: $color-default-blue;
        transition: background-color 0.2s linear;

        &:hover,
        &:active {
            background-color: $color-green !important;
            color: $color-default-blue;
        }
    }
}

.btn-alternative {
    text-decoration: none;
    color: $color-default-blue;
    font-weight: 500;
    font-size: 18px;
    background-image: url('/static/icons/btn-arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center right;
    padding: 0 30px 0 1px;
    transition: padding 0.1s linear, background-image 0.3s linear;
    display: inline-block;

    &.btn-on-dark {
        color: $color-white;
        background-color: transparent;

        &.btn-download {
            background-image: url('/static/icons/upload.svg');
        }
    }

    &:hover {
        background-image: url('/static/icons/btn-arrow-right-hover.svg');
        padding: 0 32px 0 2px;
    }

    &--login {
        background-image: url('/static/icons/login.svg');
    }
}

.btn-transparent {
    background-color: transparent;
    border: none;
    padding: 0;
}

.btn-my-wme {
    background-image: none;
    background: linear-gradient(172.98deg, #F7F8FB 2.27%, #007FA3 426.73%);

    &:hover {
        svg {

            path {
                animation: shape-up-down 1s infinite;
                transform: translateY(-3px);

                &.cls-1 {
                    animation-delay: 100ms;
                }

                &.cls-2 {
                    animation-delay: 200ms;
                }

                &.cls-3 {
                    animation-delay: 300ms;
                }

                &.cls-4 {
                    animation-delay: 400ms;
                }

                &.cls-5 {
                    animation-delay: 500ms;
                }
            }
        }
    }

    @keyframes shape-up-down {
        0% {
            transform: translateY(-3px);
        }

        50% {
            transform: translateY(3px);
        }

        100% {
            transform: translateY(-3px);
        }
    }
}

/// <reference path="../components/_variables.scss" />

.main-content .section-block.framework-section {
    background-color: $color-very-dark-blue;
}

/* adjust header so that we can push down stop accomadate CTA*/
.page-contenttype18lpgframework,
.page-contenttype16gasframework {
    .page-header {
        padding-bottom: 2rem;

        @include desktop() {
            padding-bottom: 4rem;
        }
    }
}

.framework-container {
    background-color: $color-white;
    padding: 4rem 0 1rem;
    color: $color-headings;
    border-radius: $default-border-radius $default-border-radius 0 0;

    @include desktop() {
        border-radius: $default-border-radius 0 0 $default-border-radius;
    }

    &-small {
        padding: 1rem 0;

        @include desktop() {
            height: 230px;
            margin-bottom: 4rem;
        }
    }

    h4, .h4 {
        color: $color-headings;
    }

    &.pull-up-header {
        margin-top: -6rem;
    }

    .framework-cta-in-page {

        @include desktop() {
            margin-top: -6rem;
        }

        .h3 {
            color: $color-headings;
        }
    }

    &-small {
        .framework-cta-in-page {

            @include desktop() {
                margin-top: -4rem;
            }
        }
    }

    .framework-details {
        padding: 1rem 2rem 2rem;

        @include desktop() {
            border-right: 1px solid $color-mid-blue;
        }

        &.has-next {
            border-right: none;
        }

        .framework-border-right {
            border-right: none;

            @include desktop() {
                border-right: 1px solid $color-mid-blue;
            }
        }

        h4 {
            margin-bottom: 1rem;

            img {
                vertical-align: inherit;
                max-width: 15px;
            }
        }

        h5 {
            margin-bottom: 1rem;
        }

        &--terms-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: $color-default-blue;
        }
    }
}

.lpg-framework-contentarea {
    background-color: $color-white;
    border-radius: $default-border-radius;
    padding: 3rem;
    margin-bottom: 2rem;
}

.calculator-form {
    background-color: $color-default-blue;
    border-radius: $default-border-radius;
    color: $color-white;
    padding: 4rem 3rem;

    h3, .h3, h4, .h4 {
        color: $color-white;
    }

    .wme-form-field {
        background-color: $color-white;
    }

    .input-group-text {
        background-color: $color-white;
        border-radius: 61px;
        font-size: 14px;
        line-height: 21px;
        color: $color-default-blue;
    }

    &-divide {
        opacity: .5;
        color: #fff;
        margin-top: 0;
    }

    #lpg-quote-result-date {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        opacity:.8;
        margin-top:1rem;
    }
}

/// <reference path="_variables.scss" />
.section-block:not(.overlap-on-next-section).section-block-featuredcasestudygridcomponent {
    @include desktop() {
        margin: 2rem 0;
    }
}
.section-block.section-block-secondary.section-block-featuredcasestudygridcomponent {
    @include desktop() {
        padding-top: 4rem;
    }
}

.grid-component-featured-case-study {
    &--text-area {

        @include desktop() {
            padding-left: 3rem;
        }

        a.category-pill {
            margin-top: 5px;
            display: inline-block;
        }

        &--text {
            padding-top: 2rem;

            .highlight {
                color: $color-green;
            }

            .featured-case-study--quote {
                blockquote {
                    margin-bottom: 1rem;
                }

                .featured-case-study--author {
                    color: $color-grey;
                }
            }

            .btn-alternative {
                @include xl() {
                    margin-left: 2rem;
                }
            }
        }
    }
}

/// <reference path="_variables.scss" />
.grid-component-quick-links {
    text-align: center;
    padding: 3rem 0;
    background-color: $color-very-dark-blue;

    @media only screen and (max-width: 1200px) {

        .scrolling-wrapper {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;

            .scrolling-card {
                display: inline-block;
            }
        }

        .scrolling-card {
            width: auto;
            height: 250px;
            margin-right: 1rem;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .scrolling-wrapper {
            height: 250px;
            margin-bottom: 20px;
            width: 100%;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .scrolling-wrapper {
            display: flex;
            justify-content: center;
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1* var(--bs-gutter-y));
            margin-right: calc(-.5* var(--bs-gutter-x));
            margin-left: calc(-.5* var(--bs-gutter-x));

            .scrolling-card {
                flex: 0 0 auto;
                padding-right: calc(var(--bs-gutter-x)* .5);
                padding-left: calc(var(--bs-gutter-x)* .5);
                margin-top: var(--bs-gutter-y);
            }
        }
    }

    &--link {
        padding: 2rem;
        border: 1px solid #ffffff20;
        border-radius: $default-border-radius;
        margin-bottom: 1rem;
        min-height: 215px;
        cursor: pointer;
        overflow: hidden;

        img {
            margin-bottom: 1rem;
            max-width: 35px;
        }

        &.ql {
            &-1:hover,
            &-4:hover {
                background: linear-gradient(180deg, rgba(255, 173, 0, 0.5) 0%, rgba(241, 180, 52, 0.3) 100%);
            }

            &-2:hover,
            &-5:hover {
                background: linear-gradient(180deg, rgba(0, 191, 111, 0.5) 0%, rgba(0, 191, 111, 0.255) 100%);
            }

            &-3:hover,
            &-6:hover {
                background: linear-gradient(180deg, rgba(0, 127, 163, 0.5) 0%, rgba(0, 93, 169, 0.5) 100%);
            }
        }
    }

    .ql-component-link {
        margin-top: 1rem;
        margin-bottom: 1rem;

        @include desktop() {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    &--label {
        font-size: 20px;
    }

    a {
        font-weight: 500;
        font-size: 18px;
    }

    ul.checkbox-list-horizontal {
        margin-bottom: 2rem;

        li {
            background: none;
            font-size: 24px;
            line-height: 34px;
            font-weight: 300;

            span {
                display: block;
                text-align: center;

                img {
                    margin-right: 0.5rem;
                    vertical-align: middle;
                }
            }
        }
    }
}

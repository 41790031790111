/// <reference path="_variables.scss" />
.grid-component-cta-banner {
    .cta-banner {
        background-color: $color-default-blue;
        padding: 4rem 3rem;
        color: $color-white;
        border-radius: $default-border-radius;

        .h3 {
            color: $color-white;
        }

        &.has-image {
            background-repeat: no-repeat;
            background-position: center right;
            background-size: cover;

            p {
                text-shadow: 4px 2px 9px black;
            }

            @include desktop() {
                background-size: unset;

                p {
                    text-shadow: none;
                }
            }
        }
    }
}

    .section-block-tertiary,
    .section-block-dark-blue {
        .grid-component-cta-banner {
            .cta-banner {
                background-color: $color-off-white;
                color: $color-default-blue;

                .h3 {
                    color: $color-default-blue;
                }
            }
        }
    }

    .contact-cta-in-page {
        background-color: $color-white;
        text-align: center;
        border-radius: $default-border-radius;
        padding: 3rem 2rem 2rem;
        box-shadow: 6px 11px 49px #00000040;

        &--blue {
            background-color: $color-light-blue;
        }

        .btn {
            display: block;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        .h3 {
            font-size: 32px;
            line-height: 42px;
            font-weight: 500;
        }

        &--sub {
            margin-top: -1rem;
        }

        &--number {
            margin-top: 1rem;
            font-weight: 400;
            font-size: 24px;
            line-height: 34px;

            a {
                color: $color-default-blue;
                text-decoration: none;
            }

            img {
                vertical-align: baseline;
            }
        }

        @include desktop() {
            margin: 0 2.2rem;

            &.cta-fixed {
                margin-top: -14rem;
                top: 10rem;
                position: sticky;
            }

            .btn {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

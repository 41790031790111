/// <reference path="_variables.scss" />
.grid-component-popout-card {
    padding: 3rem 0;


    &--pre-text {
        color: $color-mid-blue;
        font-weight: 500;
    }

    &--title.h3 {
        font-weight: 500;
        margin-bottom: 1rem;

        .sector-icon {
            min-width: 30px;
            margin-right: 0.5rem;
        }
    }

    &--image {
        img {
            border-radius: $default-border-radius;
        }

        margin-top: -4rem;

        @include desktop() {
            margin-top: 0;
        }
    }

    &--card {
        background-color: #fff;
        box-shadow: 6px 11px 27px 0px rgb(0 0 0 / 33%);
        padding: 3rem 3.5rem;
        border-radius: $default-border-radius;
        z-index: 999;
        position: relative;


        @include desktop() {
            margin-top: 4rem;
            margin-right: -11rem;
            margin-left: 1rem;
            min-height: 400px;
        }
    }

    &--left {

        .grid-component-popout-card--card {
            box-shadow: 6px 11px 27px 0px rgb(0 0 0 / 33%);



            @include desktop() {
                margin-right: 1rem;
                margin-left: -16rem;
                margin-top: 3rem;
            }
        }
    }
}

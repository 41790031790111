/// <reference path="_variables.scss" />

.link-list {
    &--horizontal {
        li {
            @include horizontal-list-li;
        }
    }
}

footer {
    .secondary-footer-links {
        li {
            @include horizontal-list-li;
            width: 50%;

            @include desktop() {
                width: auto;
            }
        }

        @include desktop() {
            li:not(:last-child) {
                margin-right: 1rem;
                padding-right: 1rem;
                border-right: 1px solid #4F6A85;
            }
        }
    }
}

.pre-header-links {
    margin: 0;

    li {
        @include horizontal-list-li(right);
        margin: 0 0 .5rem;

        .pre-header-border-top {
            border-top: 3px solid $color-default-blue;
            display: block;
            min-height: 1px;
            width: 1%;
            margin: auto;
            transition: width linear .3s;
            margin-bottom: 0.5rem;
        }

        &.active,
        &:hover {
            .pre-header-border-top {
                border-top-color: $color-yellow;
                width: 55%;
            }
        }

        a {
            color: #fff;
            text-decoration: none;
            /*font-weight: bold;*/
            padding: 0 1rem;
            margin: 0 1rem .5rem .5rem;
            font-size: 16px;
            font-weight: 500;

            img {
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        &:not(.last) {
            border-right: 1px solid #4F6A85;
        }
    }
}

ul {
    padding-left: 0;

    &.checkbox-list li {
        list-style-type: none;
        margin-bottom: 1rem;
        padding-left: 2rem;
        background-repeat: no-repeat;
        background-position: left 0px top 6px;
        background-image: url('/static/icons/check.svg');
    }

    &.checkbox-list-horizontal li {
        text-align: left;
    }
}

.sidebar-links {
    background-color: $color-very-dark-blue;
    color: $color-white;
    border-radius: $default-border-radius;
    padding: 3rem 2rem 2rem;

    .h3, a {
        color: $color-white;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            a {
                font-weight: 500;
                display: block;
                width: 100%;
                text-align: left;
                margin-bottom: 1rem;
            }
        }
    }

    @include desktop() {
        margin: 0 2.2rem;
        margin-top: -10rem;
        top: 10rem;
        position: sticky;
    }
}

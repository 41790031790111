/// <reference path="_variables.scss" />
.grid-component-text-and-logo {
    padding: 2rem 0;

    &--text {

        &--title.h3 {
            font-weight: 500;
            font-size: 32px;
            line-height: 42px;

            .highlight {
                color: $color-green;
            }
        }
    }

    &--image {
        text-align: center;

        @include desktop() {
            text-align: right;
        }

        img {
            margin-bottom: 2rem;
        }
    }
}

/// <reference path="_variables.scss" />

$resources-border-color: #233441;

.grid-component-resources-download {
    padding-bottom: 4rem;

    &--background {
        background-color: $color-very-dark-blue;
        color: $color-white;
        padding: 3rem;

        @include desktop() {
            border-radius: $default-border-radius 0 0 $default-border-radius;
        }
    }

    .h3 {
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        color: $color-white;
    }

    &--title-text {
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        color: $color-white;

        .highlight {
            color: $color-light-blue;
            text-transform: uppercase;
        }
    }

    &--intro-text {
        font-weight: 400;
        color: $color-off-white;
        margin-bottom: 2rem;

        @include desktop() {
            width: 68%;
        }
    }

    &--top-tips {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    &--links {


        &--list {
            margin-bottom: 3rem;
            padding: 2rem;
            border: 1px solid $resources-border-color;
            border-radius: $default-border-radius;
        }

        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;

            li {
                width: 100%;
                list-style-type: none;
                @include horizontal-list-li;
                margin-bottom: 2rem;

                @include tablet() {
                    padding-right: 2rem;
                }

                @include xl() {
                    width: 50%;
                }

                a {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    width: 100%;
                }
            }
        }
    }

    .card {
        background-color: $color-very-dark-blue;

        .card-body {
            border: 1px solid $resources-border-color;
            border-top: none;
            border-radius: 0 0 $default-border-radius $default-border-radius;
            margin-bottom: 1rem;
            padding: 2rem;


            .card-title {
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: $color-white;
                margin-bottom: 1rem;

                @include desktop() {
                    min-height: 68px;
                    margin-bottom: 2rem;
                }
            }
        }
    }


    @media only screen and (max-width: 1024px) {

        .slide {
            max-width: 100%;
            overflow-x: scroll;

            .slide-inner {
                width: 400%;
                display: flex;

                .card {
                    width: 80%;
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @media only screen and (max-width: 550px) {

        .slide {
            .slide-inner {
                width: 800%;
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .slide {
            display: none;
        }
    }

    .contact-cta-in-page {
        margin: 0;

        .h3 {
            color: $color-default-blue;
            margin-bottom: 0;
        }
    }
}

/// <reference path="_variables.scss" />
.overlay-card {
    position: relative;
    overflow: hidden;
    background-color: #000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #000;
    cursor: pointer;
    padding: 10rem 1rem;
    height: 90%;
    margin-bottom: 1.5rem;

    @include desktop() {
        height: 100%;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform .5s ease-in-out;
    }

    &::after {
        transform: scale(1.1);
    }

    &:focus,
    &:hover {
        &::after {
            transform: scale(1);
        }
    }

    .card-title {
        margin-bottom: 0;
        position: absolute;
        z-index: 1;
        bottom: 0;
        text-align: left;
    }

    .card-title {

        .btn-alternative {
            margin-bottom: 2rem;
            font-weight: 400;
            background-image: url('/static/icons/arrow-right.svg');
            text-align: left;
            text-shadow: 1px 1px 6px #000;
        }
    }
}

.grid-component-services-list {
    &--featured-section {
        /*margin-bottom: 4rem;*/

        @include desktop() {
            margin-bottom: 2rem;
        }

        .overlay-card {
            margin-top: 2rem;
            padding: 14rem 2rem;

            @include desktop() {
                margin-top: 0;
            }
        }
    }
}
